import React, { useState, useEffect, useRef } from "react";
import Message, { MessageFooter } from "@amzn/meridian/message";
import { BADGE_PHOTO_THUMBNAIL_URL, PHONE_TOOL_URL } from "src/config/ExternalLinks";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import Box from "@amzn/meridian/box";
import Textarea from "@amzn/meridian/textarea";
import Button from "@amzn/meridian/button";
import Thumbnail from "@amzn/meridian/thumbnail";
import { useAppSelector, useAppDispatch } from "src/store/store";
import {
  getCorrespondenceMessages,
  postCorrespondenceMessage,
  setCorrespondencePostStatus,
  getEmployeeInfo,
  resetState
} from "src/store/correspondenceSlice";
import { useParams } from "react-router-dom";
import { ApiCallStatus } from "src/store/type";
import Loader from "@amzn/meridian/loader";
import Icon from "@amzn/meridian/icon";
import userTokens from "@amzn/meridian-tokens/base/icon/user";
import Text from "@amzn/meridian/text";
import Image from "@amzn/meridian/image";
import Link from "@amzn/meridian/link";

const correspondence = () => {
  const [message, setMessage] = useState("");
  const messageRef = useRef<HTMLInputElement>();
  const { alias } = useAppSelector((state) => state.user);
  const { correspondencePostStatus, correspondenceMessages, correspondenceMessagesStatus, correspondenceEmployeeInfo } =
    useAppSelector((state) => state.correspondence);
  const dispatch = useAppDispatch();
  let { placementId } = useParams();

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, []);

  useEffect(() => {
    if (placementId) {
      dispatch(getCorrespondenceMessages(+placementId));
    }
  }, [placementId]);

  useEffect(() => {
    if (messageRef.current) {
      messageRef.current.scrollIntoView(
        {
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest'
        })
    }
  }, [correspondenceMessages])

  useEffect(() => {
    if (correspondencePostStatus === ApiCallStatus.Success) {
      setMessage("");
      dispatch(setCorrespondencePostStatus(ApiCallStatus.Idle));
      if (placementId) {
        dispatch(getCorrespondenceMessages(+placementId));
      }
    }
  }, [correspondencePostStatus]);

  const postCorrespondence = async () => {
    if (placementId) {
      await dispatch(postCorrespondenceMessage({ correspondence: message, accommodationId: +placementId }));
    }
  };

  const fetchEmployeeInfo = (alias: string) => {
    dispatch(getEmployeeInfo(alias));
  };

  return (
    <>
      <Column>
        <Row widths={["grid-8", "grid-3"]}>
          <Box type='outline' spacingInset='500' minHeight={380} maxHeight={380} overflowY='scroll'>
            <Column ref={messageRef}>
              {correspondenceMessagesStatus === ApiCallStatus.Pending ? (
                <Loader size='medium' />
              ) : (
                correspondenceMessages.map((correspondence) => {
                  return (
                    <Message
                      name={`${correspondence.createdByName}`}
                      avatarImageSource={`${BADGE_PHOTO_THUMBNAIL_URL}${correspondence.createdByAlias}`}
                      datetime={correspondence.createdOnDateTime}
                      type={correspondence.fromDecisionHub ? "incoming" : "outgoing"}
                      maxWidth='750px'
                      size='small'
                      data-testid='correspondence-message'
                      key={correspondence.createdOnDateTime}
                    >
                      {correspondence.content}

                      <MessageFooter>
                        <Row spacing='300'>
                          <Text color='secondary'>
                            {new Date(correspondence.createdOnDateTime).toLocaleDateString()}{" "}
                          </Text>
                          <Button
                            aria-label='view profile'
                            size='small'
                            circular
                            type='icon'
                            onClick={() => {
                              fetchEmployeeInfo(correspondence.createdByAlias);
                            }}
                          >
                            <Icon tokens={userTokens}>View profile</Icon>
                          </Button>
                        </Row>
                      </MessageFooter>
                    </Message>
                  );
                })
              )}
            </Column>
          </Box>
          {correspondenceEmployeeInfo.alias !== "" && (
            <Box type='outline' spacingInset='500' minHeight={380} maxHeight={380}>
              <Column spacing='300'>
                <Image viewportHeight='150px' src={`${BADGE_PHOTO_THUMBNAIL_URL}${correspondenceEmployeeInfo.alias}`} />
                <Row>
                  <Text type='h100'>Name: </Text>
                  <Text type='b300'>{correspondenceEmployeeInfo.fullName}</Text>
                </Row>
                <Row>
                  <Text type='h100'>Alias: </Text>
                  <Text type='b300'>
                    <Link
                      type='secondary'
                      rel='noopener noreferrer'
                      target='_blank'
                      href={`${PHONE_TOOL_URL}${correspondenceEmployeeInfo.alias}`}
                    >
                      {correspondenceEmployeeInfo.alias}
                    </Link>
                  </Text>
                </Row>
                <Row>
                  <Text type='h100'>Job: </Text>
                  <Text type='b300'>{correspondenceEmployeeInfo.jobTitle}</Text>
                </Row>
                <Row>
                  <Text type='h100'>Manager Name: </Text>
                  <Text type='b300'>{correspondenceEmployeeInfo.managerFullName}</Text>
                </Row>
                <Row>
                  <Text type='h100'>Manager Alias: </Text>
                  <Text type='b300'>
                    <Link
                      type='secondary'
                      rel='noopener noreferrer'
                      target='_blank'
                      href={`${PHONE_TOOL_URL}${correspondenceEmployeeInfo.managerAlias}`}
                    >
                      {correspondenceEmployeeInfo.managerAlias}
                    </Link>
                  </Text>
                </Row>
              </Column>
            </Box>
          )}
        </Row>
        <Row widths={["fit", "grid-5", "grid-2"]}>
          <Thumbnail source={`${BADGE_PHOTO_THUMBNAIL_URL}${alias}`} />
          <Textarea
            value={message}
            onChange={setMessage}
            placeholder='Write your correspondence message here...'
            error={false}
            id='post-correspondence-text-area'
          />
          <Button
            size='large'
            disabled={message.length === 0 || correspondencePostStatus === ApiCallStatus.Pending}
            onClick={() => {
              postCorrespondence();
            }}
          >
            {correspondencePostStatus === ApiCallStatus.Pending ? <Loader size='small' /> : "Post Correspondence"}
          </Button>
        </Row>
      </Column>
    </>
  );
};

export default correspondence;
