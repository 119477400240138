import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ErrorRecord } from "src/hooks/useForm";
import JmrService from "src/services/jmr/jmrService";
import JobsService, { JobRequirement } from "src/services/jobsService";
import IncidentService from "src/services/incident/incidentService";
import PeopleApiService from "src/services/peopleApiService";
import RegionTaskService from "src/services/regionTasksService";
import CapacityUsageService from "src/services/capacityUsage/capacityUsageService";
import { Task } from "src/store/regionTasksPageSlice";
import { Site } from "src/store/siteSlice";
import { uniqueId } from "lodash";
import { SiteJobCapacityUsageData } from "src/services/capacityUsage/types/siteJobCapacityUsageData";
import {
  RANK_REPRESENTING_WORST_MATCH_FOR_UI,
  RANK_REPRESENTING_WORST_MATCH_FROM_SERVICE,
} from "src/config/JmrConstants";
import { datePickerStringToDateObj } from "src/utils/dateUtils";
import EmailDistributionService from "src/services/emailDistribution/emailDistributionService";
import { DEFAULT_TABLE_PAGE_SIZE } from "src/constants/Pagination";
import { ACCOMMODATION_STATUS } from "src/config/AccommodationStatusForJmrUpdate";
import { AustinCasesByAlias } from "src/services/incident/types/austinCasesByAlias";
import { AUSTIN } from "src/components/pages/newJMR/backgroundPage/associateInformation/caseSource/constant";
import { INDEFINITE_RESTRICTIONS_ID } from "src/config/AccommodationStatusId";
import { ApiCallStatus } from "src/store/type";

export enum JmrFlowTypes {
  NewRestrictions = "NewRestrictions",
  AlternativePlacementRecommended = "AlternativePlacementRecommended",
  CreateNewAccommodation = "CreateNewAccommodation",
  UpdateDates = "UpdateDates",
  CompleteTransfer = "CompleteTransfer",
  ProviderPlacedOffWork = "ProviderPlacedOffWork",
}

export enum IdentifyBodyLocationEnum {
  LEFT = "L",
  RIGHT = "R",
  BOTH = "B",
}

export enum JmrJobTypes {
  ProcessPath = "Process Path",
  TLD = "TLD Assignment",
}

export interface JmrTask extends Task {
  isExpanded: boolean;
  restrictedValue: string;
  bodyLocationValue?: string;
}

export interface TaskRestriction {
  taskId: number;
  originalValue: number;
  restrictedValue: number;
  taskName: string;
}

const initialBackgroundFormData = {
  alias: "",
  fullName: "",
  job: "",
  employeeID: "",
  shift: "",
  caseID: "",
  caseSource: "",
  managerName: "",
  managerAlias: "",
  cannotWorkNormalShiftHrs: false,
  cannotWorkMoreThan40Hrs: false,
  indefiniteRestrictions: false,
  providerPlacedOffWork: false,
};

interface ProcessingMessages {
  type: string;
  content: string;
}

export interface BackgroundFormData {
  alias: string;
  fullName: string;
  siteLocation: string;
  site: Site;
  job: string;
  employeeID: string;
  personId: string;
  country: string;
  shift: string;
  shiftLength: number;
  caseID: string;
  caseSource: string;
  restrictionStartDate: string;
  restrictionEndDate: string;
  cannotWorkNormalShiftHrs: boolean;
  cannotWorkMoreThan40Hrs: boolean;
  indefiniteRestrictions: boolean;
  providerPlacedOffWork: boolean;
  limitedHoursPerDay: string;
  managerName: string;
  managerAlias: string;
  limitedHoursPerWeek: string;
  caseIdValid: boolean;
  accommodationStatus: string;
}

export interface BackgroundFormFieldLength {
  maxAliasLength: number;
  maxFullNameLength: number;
  maxJobLength: number;
  maxEmployeeIdLength: number;
  maxShiftCodeLength: number;
  maxManagerAliasLength: number;
  maxManagerNameLength: number;
  maxCaseIdLength: number;
}

const backgroundFormFieldLengthValues: BackgroundFormFieldLength = {
  maxAliasLength: 255,
  maxFullNameLength: 150,
  maxJobLength: 150,
  maxEmployeeIdLength: 150,
  maxShiftCodeLength: 150,
  maxManagerAliasLength: 150,
  maxManagerNameLength: 150,
  maxCaseIdLength: 255,
};

export interface AllocatedJobShift {
  jobId: number;
  shiftId: number;
  allocatedHours: number;
  tasks: [
    {
      taskId: number;
      assignedValue: string;
    }
  ];
}

export interface AllocatedTask {
  taskId: number;
  assignedValue: string | number;
  taskName: string;
}

export interface JmrShift {
  shiftId: number;
  shiftCode: string;
  shiftLengthHours: number;
  slots: number;
  totalHours: number;
}

export interface JmrResult {
  jobId: number;
  rank: number;
  jobType: string;
  name: string;
  matchedTasks: TaskRestriction[];
  jobRequirements: JobRequirement[];
  allocatedTasks: AllocatedTask[];
  jobCapacity?: number;
  shifts: JmrShift[] | null;
  disable: boolean;
}

export interface TableDataRow extends Omit<JmrResult, "shifts"> {
  id: string;
  isChecked: boolean;
  totalHours: string;
  shiftCode?: string;
  shiftId?: number;
}

export interface JmrFilter {
  searchField: string;
  currentPage: number;
  totalPages: number;
  jobType: JmrJobTypes;
  perPage: number;
}

export interface ApprovalFormData {
  emailRecipients: string;
  emailContent: string;
}

export type JmrPageState = {
  pageProgress: number;
  isCancelModalOpen: boolean;
  backgroundFormData: BackgroundFormData;
  backgroundFormErrors: ErrorRecord<BackgroundFormData>;
  backgroundFormFieldLengths: BackgroundFormFieldLength;
  regionTasks: Task[];
  selectedTaskNames: string[];
  selectedTasks: JmrTask[];
  restrictionsFormErrors: any;
  jmrResults: TableDataRow[];
  jmrFilter: JmrFilter;
  approvalFormData: ApprovalFormData;
  approvalFormErrors: ErrorRecord<ApprovalFormData>;
  isResultsPageValid: boolean;
  isRestrictionsModalOpen?: string;
  createdAccommodationId: number;
  jmrFlowType: JmrFlowTypes;
  placementId: number;
  accommodationSavingStatus: ApiCallStatus;
  austinCasesByAlias: AustinCasesByAlias[];
  searchedAlias: string;
  isLoadingPAPI: boolean;
  isLoadingIncident: boolean;
  isAUSTINDown?: boolean;
  processingMessages: ProcessingMessages[];
  externalCaseId: string;
  accommodationType: string;
  showCaseIdRequiredText: boolean;
  dpFeatureFlag: boolean;
  isProviderPlacedOffWorkModalOpen: boolean;
  tldAlertModal: {
    open: boolean;
    hasBeenOpened: boolean;
    resultId: string;
    jobId: number;
  };
};

const initialState: JmrPageState = {
  pageProgress: 0,
  isCancelModalOpen: false,
  backgroundFormData: initialBackgroundFormData as BackgroundFormData,
  backgroundFormErrors: {},
  backgroundFormFieldLengths: backgroundFormFieldLengthValues,
  regionTasks: [],
  selectedTaskNames: [],
  selectedTasks: [],
  restrictionsFormErrors: {},
  jmrResults: [],
  jmrFilter: {
    searchField: "",
    currentPage: 1,
    totalPages: 1,
    perPage: DEFAULT_TABLE_PAGE_SIZE,
    jobType: JmrJobTypes.ProcessPath,
  },
  approvalFormData: { emailContent: "", emailRecipients: "" },
  approvalFormErrors: {},
  isResultsPageValid: false,
  isRestrictionsModalOpen: undefined,
  createdAccommodationId: -1,
  jmrFlowType: JmrFlowTypes.CreateNewAccommodation,
  placementId: 0,
  accommodationSavingStatus: ApiCallStatus.Idle,
  austinCasesByAlias: [],
  searchedAlias: "",
  isLoadingPAPI: false,
  isLoadingIncident: false,
  isAUSTINDown: false,
  processingMessages: [],
  externalCaseId: "",
  accommodationType: "",
  showCaseIdRequiredText: false,
  // The userSlice is the source for this. In order to share that value with
  // this newJmrSlice, app.tsx dispatches an async thunk action. This is done cause we can't use
  // another redux slice's state directly in a different redux slice.
  dpFeatureFlag: false,
  isProviderPlacedOffWorkModalOpen: false,
  tldAlertModal: {
    open: false,
    hasBeenOpened: false,
    resultId: "",
    jobId: -1,
  },
};

const jmrResultToTableRow = (
  jmrResult: JmrResult,
  capacityUsageMap: Map<number, SiteJobCapacityUsageData>
): TableDataRow[] => {
  const commonData = {
    id: uniqueId(),
    isChecked: false,
    totalHours: "0",
    allocatedTasks: [],
    // if the rank is smaller than worst rank, give it really high number
    // so that it shows up at the end for better UX
    rank:
      +jmrResult.rank > RANK_REPRESENTING_WORST_MATCH_FROM_SERVICE
        ? +jmrResult.rank
        : RANK_REPRESENTING_WORST_MATCH_FOR_UI,
  };
  /**
   * Currently, the backend incorrectly sends null back instead of an
   * empty array for some of the jmr results. This breaks our UI. This is
   * a quickfix that can be removed once the root cause is found and fixed
   * in the backend.
   * https://sim.amazon.com/issues/P71631986
   */
  if (jmrResult.shifts === null) {
    jmrResult.shifts = [];
  }
  const rows: TableDataRow[] =
    jmrResult.shifts.length > 0
      ? jmrResult.shifts.map((shift) => ({
          ...jmrResult,
          ...commonData,
          id: uniqueId(),
          shiftCode: shift.shiftCode,
          shiftId: shift.shiftId,
          jobCapacity: calculateRemainingCapacity(jmrResult.jobId, shift, capacityUsageMap),
        }))
      : [
          {
            ...jmrResult,
            ...commonData,
          },
        ];
  return rows;
};

/**
 * Calculate remaining capacity for a shift
 */
const calculateRemainingCapacity = (
  jobId: number,
  shift: JmrShift,
  capacityUsageMap: Map<number, SiteJobCapacityUsageData>
) => {
  let shiftsMap = CapacityUsageService.createShiftsMapForJob(jobId, capacityUsageMap);
  let capacityRemaining = shift.totalHours;

  if (shiftsMap.has(shift.shiftId)) {
    capacityRemaining = capacityRemaining - shiftsMap.get(shift.shiftId)!.allocatedHours;
  }
  return capacityRemaining >= 0 ? capacityRemaining : 0;
};

export const getAustinCasesByAlias = createAsyncThunk("newJmr/getAustinCasesByAlias", async (alias: string) => {
  const { data } = await IncidentService.getAustinCasesByAlias(alias);
  return data;
});

export const getEmployeeInfo = createAsyncThunk("newJmr/getEmployeeInfo", async (employeeLogin: string) => {
  const { data } = await PeopleApiService.getEmployeeInfo(employeeLogin);
  return data;
});

/**
 * get tasks of a region by region Id
 * @returns {Object}
 */
export const getRegionTasksById = createAsyncThunk("newJmr/getRegionTasksById", async (_, { getState }) => {
  const siteLocation = await (getState() as any).newJmrPage.backgroundFormData.site.regionId;
  const { data } = await RegionTaskService.getTasksByRegionId(siteLocation);
  return data;
});

/**
 * Will result in setting the dpFeatureFlag to true if this async thunk action is dispatched.
 */
export const setDecisionPortalFeatureFlagInNewJmrSlice = createAsyncThunk(
  "newJmr/setDecisionPortalFeatureInNewJmrSlice",
  async () => {
    return true;
  }
);

/**
 * get job
 * @returns {Object}
 */
export const getJob = createAsyncThunk("newJmr/getJob", async (jobId: number) => {
  const { data } = await JobsService.getJobById(jobId);
  return data;
});

export const createAccommodation = createAsyncThunk("newJmr/createAccommodation", async (_, { getState }) => {
  const state = await (getState() as any);
  const backgroundFormData: BackgroundFormData = state.newJmrPage.backgroundFormData;
  const jmrFlowType = state.newJmrPage.jmrFlowType;
  const placementId = state.newJmrPage.placementId;
  const selectedTasks: JmrTask[] = state.newJmrPage.selectedTasks;
  const jmrResults: TableDataRow[] = state.newJmrPage.jmrResults.filter(
    (jmrResult: TableDataRow) => jmrResult.isChecked
  );
  const email: string = state.newJmrPage.approvalFormData.emailRecipients;

  // Data from date picker is recorded in local date time
  // this converts it to utc.
  const utcRestrictionStartDate = datePickerStringToDateObj(
    backgroundFormData.restrictionStartDate
  )?.toISOString() as string;

  const utcRestrictionEndDate = backgroundFormData.site.participatingInDecisionHubPilot && backgroundFormData.indefiniteRestrictions ? null : datePickerStringToDateObj(
    backgroundFormData.restrictionEndDate
  )?.toISOString() as string;

  const accommodationData = {
    associateFullName: backgroundFormData.fullName,
    requesterLogin: state.user.alias,
    startAt: utcRestrictionStartDate,
    endAt: utcRestrictionEndDate,
    managerFullName: backgroundFormData.managerName,
    managerAlias: backgroundFormData.managerAlias,
    employeeId: backgroundFormData.employeeID,
    personId: backgroundFormData.personId,
    job: backgroundFormData.job,
    login: backgroundFormData.alias,
    fc: backgroundFormData.siteLocation,
    siteId: backgroundFormData.site.id,
    accommodationType: backgroundFormData.caseSource.toUpperCase(),
    externalCaseId: backgroundFormData.caseID,
    weeklyLimitedHours: +backgroundFormData.limitedHoursPerWeek,
    modifiedShiftLength: backgroundFormData.limitedHoursPerDay
      ? +backgroundFormData.limitedHoursPerDay
      : +backgroundFormData.shiftLength,
    canWorkNormalShiftHours: !backgroundFormData.cannotWorkNormalShiftHrs,
    canWorkMoreThan40Hours: !backgroundFormData.cannotWorkMoreThan40Hrs,
    shiftCode: backgroundFormData.shift,
    normalShiftLength: +backgroundFormData.shiftLength,
    workRestrictions: selectedTasks.map((t) => ({
      taskId: t.taskId,
      hoursRestricted: t.restrictedValue || "No",
      taskName: t.label,
      bodyLocation: t.bodyLocationValue,
    })),
    allocatedJobShifts: jmrResults.map((r) => ({
      jobId: r.jobId,
      shiftId: r.shiftId,
      allocatedHours: +r.totalHours,
      jobName: r.name,
      shiftName: r.shiftCode,
    })),
  };

  if (jmrFlowType === JmrFlowTypes.CreateNewAccommodation) {
    const { data } = await JmrService.createAccommodation({
      accommodation: accommodationData as any,
      emailRecipients: email,
    });
    return data;
  } else {
    const updatePayload = {
      accommodation: {
        ...accommodationData,
        id: placementId,
      } as any,
      emailRecipients: email,
    };
    if (jmrFlowType === JmrFlowTypes.NewRestrictions) {
      updatePayload.accommodation.accommodationStatus = ACCOMMODATION_STATUS.NEW_RESTRICTIONS_RECEIVED;
      const { data } = await JmrService.updateAccommodationRestrictions(updatePayload);
      return data;
    } else if (jmrFlowType === JmrFlowTypes.AlternativePlacementRecommended) {
      updatePayload.accommodation.accommodationStatus = ACCOMMODATION_STATUS.ALTERNATIVE_PLACEMENT_RECOMMENDED;
      const { data } = await JmrService.recommendAlternativePlacement(updatePayload);
      return data;
    } else if (jmrFlowType === JmrFlowTypes.CompleteTransfer) {
      updatePayload.accommodation.accommodationStatus = ACCOMMODATION_STATUS.TRANSFER_PENDING;
      const { data } = await JmrService.completeTransferPlacement(updatePayload);
      return data;
    } else if (jmrFlowType === JmrFlowTypes.UpdateDates) {
      updatePayload.accommodation.accommodationStatus = ACCOMMODATION_STATUS.ALTERNATIVE_PLACEMENT_RECOMMENDED;
      const { data } = await JmrService.updateRestrictionDates(updatePayload);
      return data;
    } else if (jmrFlowType === JmrFlowTypes.ProviderPlacedOffWork) {   // for both create or update PPOW
      const { data } = await JmrService.providerPlacedOffWork(updatePayload);
      return data;
    }
  }
});

/**
 * get placement detail data
 * @returns {Object}
 */
export const getPlacementDetails = createAsyncThunk(
  "newJmr/getPlacementDetails",
  async (accommodationId: number, { dispatch, getState }) => {
    const { data } = await JmrService.getAccommodation(accommodationId);
    if (data.accommodationType === AUSTIN) {
      dispatch(getAustinCasesByAlias(data.login));

      if (!data.caseIdValid) {
        // reset case id if invalid to force user to pick one.
        data.externalCaseId = "";
      }
    }
    return data;
  }
);

/**
 * Get email settings by site
 */
export const getEmailSettingsForSite = createAsyncThunk("newJmr/getEmailSettings", async (_, { getState }) => {
  const { backgroundFormData } = await (getState() as any).newJmrPage;
  const { data } = await EmailDistributionService.getForSite(backgroundFormData.site?.id);
  return data;
});

/**
 * generate job matches
 * @returns {Object}
 */
export const generateJobMatches = createAsyncThunk("newJmr/generateJobMatches", async (_, { getState }) => {
  const newJmrPageState = await (getState() as any).newJmrPage;
  const shiftLength = newJmrPageState.backgroundFormData.cannotWorkNormalShiftHrs
    ? +newJmrPageState.backgroundFormData.limitedHoursPerDay
    : +newJmrPageState.backgroundFormData.shiftLength;

  const payload = {
    location: newJmrPageState.backgroundFormData?.siteLocation,
    siteId: newJmrPageState.backgroundFormData.site?.id,
    shiftLength: shiftLength,
    taskRestrictions: newJmrPageState.selectedTasks?.map((t: JmrTask) => ({
      taskId: t.taskId,
      measureUnit: t.measureUnit,
      restrictedValue: t.measureUnit === "YES/NO" ? "No" : +t.restrictedValue,
    })),
  };
  const { data: jmrResultsData } = await JmrService.generateJobMatches(payload);
  const { data: jobCapacityUsageData } = await CapacityUsageService.getCapacityUsageForSite(payload.siteId);
  return { jmrResultsData, jobCapacityUsageData };
});

const shouldEndDateBeEmptiedOut = (currentAccommodationStatusId: number, doesDateRepresentIndefiniteRestrictions: boolean) => {
  return currentAccommodationStatusId === INDEFINITE_RESTRICTIONS_ID || doesDateRepresentIndefiniteRestrictions;
}

/** New JMR Page Slice */
const { reducer, actions } = createSlice({
  name: "newJmrPageSlice",
  initialState,
  reducers: {
    clearAustinCasesByAlias: (state) => {
      state.austinCasesByAlias = [];
    },
    clearProcessingMessagesData: (state) => {
      state.processingMessages = [];
      state.externalCaseId = "";
      state.accommodationType = "";
    },
    setBackgroundFormData: (state, action) => {
      // ensure data fields have consistent values.
      // when the cannotWorkNormalShiftHrs checkbox is unchecked, the limitedHoursPerDay value should be cleared
      if(!action.payload.cannotWorkNormalShiftHrs) {
        // set the value to undefined instead of null or "", so that +limitedHoursPerDay evaluates to NaN if never set on UI.
        // NaN becomes null when submitted to backend from UI correctly, but null or "" becomes 0, distorting the user intention
        action.payload.limitedHoursPerDay = undefined;
      }
      // same for cannotWorkMoreThan40Hrs and limitedHoursPerWeek
      if(!action.payload.cannotWorkMoreThan40Hrs) {
        action.payload.limitedHoursPerWeek = undefined;
      }
      state.backgroundFormData = action.payload;
    },
    setBackgroundFormErrors: (state, action) => {
      state.backgroundFormErrors = action.payload;
    },
    setPageProgress: (state, action) => {
      state.pageProgress = action.payload;
    },
    setIsCancelModalOpen: (state, action) => {
      state.isCancelModalOpen = action.payload;
    },
    setSelectedTaskNames: (state, action) => {
      state.selectedTaskNames = action.payload;
    },
    setSelectedTasks: (state, action) => {
      state.selectedTasks = action.payload;
    },
    setRestrictionsFormErrors: (state, action) => {
      state.restrictionsFormErrors = action.payload;
    },
    setJmrResults: (state, action) => {
      state.jmrResults = action.payload;
    },
    setJmrFilter: (state, action) => {
      state.jmrFilter = action.payload;
    },
    setApprovalFormData: (state, action) => {
      state.approvalFormData = action.payload;
    },
    setApprovalFormErrors: (state, action) => {
      state.approvalFormErrors = action.payload;
    },
    setIsResultsPageValid: (state, action) => {
      state.isResultsPageValid = action.payload;
    },
    setCreatedAccommodationId: (state, action) => {
      state.createdAccommodationId = action.payload;
    },
    setPlacementId: (state, action) => {
      state.placementId = action.payload;
    },
    setJmrFlowType: (state, action) => {
      state.jmrFlowType = action.payload;
    },
    setSearchedAlias: (state, action) => {
      state.searchedAlias = action.payload;
    },
    setIsAUSTINDown: (state, action) => {
      state.isAUSTINDown = action.payload;
    },
    setShowCaseIdRequiredText: (state, action) => {
      state.showCaseIdRequiredText = action.payload;
    },
    setIsProviderPlacedOffWorkModalOpen: (state, action) => {
      state.isProviderPlacedOffWorkModalOpen = action.payload;
    },
    setAccommodationSavingStatus: (state, action) => {
      state.accommodationSavingStatus = action.payload;
    },
    setIsTldAlertModalOpen: (state, action) => {
      state.tldAlertModal = {
        ...state.tldAlertModal,
        ...(action.payload.open ? {
          open: true,
          hasBeenOpened: true,
          jobId: action.payload.jobId,
          resultId: action.payload.resultId,
        } : {
          open: false,
          jobId: -1,
          resultId: '',
        }),
      }
    },
    resetTldAlertModal: (state) => {
      state.tldAlertModal = {
        open: false,
        hasBeenOpened: false,
        jobId: -1,
        resultId: '',
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRegionTasksById.fulfilled, (state, { payload }) => {
      state.regionTasks = payload.map((t: Task) => ({
        ...t,
        isExpanded: true,
      }));
    });
    builder.addCase(getAustinCasesByAlias.pending, (state, { payload }) => {
      state.isLoadingIncident = true;
    });
    builder.addCase(getAustinCasesByAlias.fulfilled, (state, { payload }) => {
      state.isAUSTINDown = false;
      state.isLoadingIncident = false;
      state.austinCasesByAlias = payload;
    });
    builder.addCase(getAustinCasesByAlias.rejected, (state, { payload }) => {
      state.isAUSTINDown = true;
      state.isLoadingIncident = false;
    });
    builder.addCase(getEmployeeInfo.pending, (state) => {
      state.isLoadingPAPI = true;
    });
    builder.addCase(getEmployeeInfo.fulfilled, (state, { payload }) => {
      state.backgroundFormData.fullName = `${payload.basicInfo?.firstName} ${payload.basicInfo?.lastName}`;
      state.backgroundFormData.employeeID = payload.basicInfo?.legacyEmployeeId;
      state.backgroundFormData.personId = payload.basicInfo?.personId;
      state.backgroundFormData.job = payload.basicInfo?.businessTitle;
      state.backgroundFormData.managerAlias = payload.basicInfo?.managerLogin;
      state.backgroundFormData.managerName = payload.basicInfo?.managerName;
      state.backgroundFormData.shift = payload.job?.workScheduleId.trim();
      state.backgroundFormData.siteLocation = payload.workLocation?.buildingCode;
      state.isLoadingPAPI = false;
    });
    builder.addCase(getEmployeeInfo.rejected, (state) => {
      state.backgroundFormData.fullName = "";
      state.backgroundFormData.employeeID = "";
      state.backgroundFormData.personId = "";
      state.backgroundFormData.job = "";
      state.backgroundFormData.managerAlias = "";
      state.backgroundFormData.managerName = "";
      state.backgroundFormData.shift = "";
      state.backgroundFormData.siteLocation = "";
      state.isLoadingPAPI = false;
    });
    builder.addCase(generateJobMatches.fulfilled, (state, { payload }) => {
      let { jmrResultsData, jobCapacityUsageData } = payload;
      const capacityUsageMap = new Map(
        jobCapacityUsageData.map((jobCapacityUsage) => [jobCapacityUsage.jobId, jobCapacityUsage])
      );
      state.jmrResults = jmrResultsData
        .map((jmrResult: JmrResult) => jmrResultToTableRow(jmrResult, capacityUsageMap))
        .flat(1);
    });
    builder.addCase(getJob.fulfilled, (state, { payload }) => {
      state.jmrResults = state.jmrResults.map((jmrResult: TableDataRow) =>
        jmrResult.jobId === payload.id ? { ...jmrResult, jobRequirements: payload.jobRequirements } : jmrResult
      );
    });
    builder.addCase(createAccommodation.fulfilled, (state, { payload }) => {
      if (payload) {
        state.createdAccommodationId = payload.id;
        state.accommodationSavingStatus = ApiCallStatus.Success;
        state.processingMessages = payload.processingMessages;
        state.externalCaseId = payload.externalCaseId;
        state.accommodationType = payload.accommodationType;
      }
    });
    builder.addCase(createAccommodation.pending, (state, { payload }) => {
      state.accommodationSavingStatus = ApiCallStatus.Pending;
    });
    builder.addCase(createAccommodation.rejected, (state, { payload }) => {
      state.accommodationSavingStatus = ApiCallStatus.Error;
    });
    builder.addCase(getPlacementDetails.fulfilled, (state, { payload }) => {
      const doesDateRepresentIndefiniteRestrictions = state.dpFeatureFlag &&
        payload.endAt.split('T')[0] === "3000-02-02";

      state.backgroundFormData = {
        ...state.backgroundFormData,
        alias: payload.login,
        fullName: payload.associateFullName,
        siteLocation: payload.fc,
        job: payload.job,
        employeeID: payload.employeeId,
        personId: payload.personId,
        shift: payload.shiftCode,
        shiftLength: payload.normalShiftLength,
        // if cannot work normal shift hours checkbox is unchecked, there should not be a value for its text field
        limitedHoursPerDay: payload.canWorkNormalShiftHours ? undefined: payload.modifiedShiftLength,
        managerAlias: payload.managerAlias,
        managerName: payload.managerFullName,
        caseSource: payload.accommodationType,
        caseID: payload.externalCaseId,
        caseIdValid: payload.caseIdValid,
        cannotWorkNormalShiftHrs: !payload.canWorkNormalShiftHours,
        cannotWorkMoreThan40Hrs: !payload.canWorkMoreThan40Hours,
        // this value controls the indefinite restrictions checkbox
        indefiniteRestrictions: (doesDateRepresentIndefiniteRestrictions),
        // if cannot work more than 40 hours checkbox is unchecked, there should not be a value for its text field
        limitedHoursPerWeek: payload.canWorkMoreThan40Hours ? undefined: payload.weeklyLimitedHours,
        accommodationStatus: payload.accommodationStatus.status,
      };

      if (
        [JmrFlowTypes.AlternativePlacementRecommended, 
          JmrFlowTypes.UpdateDates, 
          JmrFlowTypes.CompleteTransfer].includes(state.jmrFlowType)
      ) {
        if (JmrFlowTypes.CompleteTransfer !== state.jmrFlowType) {
          state.backgroundFormData = {
            ...state.backgroundFormData,
            site: payload.site,
            // end date will be empty if current status is indefinite restrictions. Refer: https://issues.amazon.com/issues/P113097040
            restrictionEndDate: shouldEndDateBeEmptiedOut(
              payload.accommodationStatusId, doesDateRepresentIndefiniteRestrictions) ? "" : payload.endAt.split("T")[0],
            restrictionStartDate: payload.startAt.split("T")[0],
          };
        }
        if (JmrFlowTypes.CompleteTransfer !== state.jmrFlowType
          || JmrFlowTypes.CompleteTransfer === state.jmrFlowType &&
          payload.previousSite.regionId === payload.site.regionId) {
            state.selectedTasks = payload.workRestrictions.map((restriction: any) => ({
              taskId: restriction.taskId,
              label: restriction.task.label,
              restrictedValue: restriction.hoursRestricted,
              measureUnit: restriction.task.measureUnit.name,
              bodyLocationValue: restriction.bodyLocation,
            }));
            state.selectedTaskNames = payload.workRestrictions.map((restriction: any) => restriction.task.label);
        }
      }
    });
    builder.addCase(getEmailSettingsForSite.fulfilled, (state, { payload }) => {
      const backgroundFormData: BackgroundFormData = state.backgroundFormData;
      const managerEmail = `${backgroundFormData.managerAlias}@amazon.com`;
      let emailRecipients = payload?.placementEmailRecipients ?? '';
      if (payload?.supervisorsForPlacementEmail) {
        emailRecipients = emailRecipients.trim().length > 0 ? `${managerEmail};${payload?.placementEmailRecipients}` : `${managerEmail}`;
      }
      state.approvalFormData.emailRecipients = emailRecipients;
    });
    // Can be removed once decision portal is live.
    builder.addCase(setDecisionPortalFeatureFlagInNewJmrSlice.fulfilled, (state, {payload}) => {
      state.dpFeatureFlag = payload;
    });
  },
});

export const {
  clearAustinCasesByAlias,
  clearProcessingMessagesData,
  setPageProgress,
  setIsCancelModalOpen,
  setBackgroundFormData,
  setBackgroundFormErrors,
  setSelectedTaskNames,
  setSelectedTasks,
  setRestrictionsFormErrors,
  setJmrResults,
  setJmrFilter,
  setApprovalFormData,
  setIsResultsPageValid,
  setCreatedAccommodationId,
  setApprovalFormErrors,
  setPlacementId,
  setJmrFlowType,
  setSearchedAlias,
  setIsAUSTINDown,
  setShowCaseIdRequiredText,
  setIsProviderPlacedOffWorkModalOpen,
  setAccommodationSavingStatus,
  setIsTldAlertModalOpen,
  resetTldAlertModal,
} = actions;

export default reducer;

