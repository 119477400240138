import React from "react";
import Modal, { ModalFooter } from "@amzn/meridian/modal";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import Text from "@amzn/meridian/text";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  getJob,
  setJmrResults,
  setIsTldAlertModalOpen,
  setJmrFilter,
  JmrJobTypes,
} from "src/store/newJmrPageSlice";
import "./tldAlertModal.scss";

const tldAlertModal = () => {

  const {
    tldAlertModal,
    jmrResults,
    jmrFilter,
  } = useAppSelector(state => state.newJmrPage);

  const dispatch = useAppDispatch();
  const onClose = () => dispatch(setIsTldAlertModalOpen(false));

  const checkJob = () => {
    dispatch(getJob(tldAlertModal.jobId));
    dispatch(
      setJmrResults(
        jmrResults.map(r => (
          r.id === tldAlertModal.resultId 
            ? { ...r, isChecked: !r.isChecked } 
            : r
          )
        )
      )
    );
  };

  return (
    <Modal
      title="Are you sure TLD is the best match?"
      open={tldAlertModal.open}
      scrollContainer="viewport"
      closeLabel="Close"
      aria-describedby="tld-modal-description"
      width={550}
      data-cy="new-jmr__tld-modal"
    >
      <Text id="tld-modal-description" className="tld-alert-modal-body">
        I acknowledge that I have reviewed the available process path options 
        (with or without modification) and TLD placement is the best (only?) 
        option for the associate.
      </Text>
      <ModalFooter>
        <Row alignmentHorizontal="end" widths="fit">
          <Button
            type="secondary"
            size="small"
            data-cy="tld-alert-modal__go-back"
            onClick={() => {
              dispatch(
                setJmrFilter({
                  ...jmrFilter,
                  jobType: JmrJobTypes.ProcessPath,
                })
              );
              onClose();
            }}
          >
            Go Back
          </Button>
          <Button
            type="primary"
            size="small"
            data-cy="tld-alert-modal__confirm"
            onClick={() => {
              checkJob();
              onClose();
            }}
          >
            Confirm Selection
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

export default tldAlertModal;
