import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CorrespondenceService, {
  CorrespondenceRequestPayload,
  CorrespondenceResponsePayload
} from "src/services/correspondenceService";
import { ApiCallStatus } from "./type";
import { sortBasedOnStrategy, SortStrategy } from "src/utils/sortStrategies";
import PeopleApiService from "src/services/peopleApiService";

/**
 * get correspondence messages
 * @returns {Object}
 */
export const getCorrespondenceMessages = createAsyncThunk(
  "accommodation/getCorrespondenceMessages",
  async (accommodationId: number) => {
    const { data } = await CorrespondenceService.getCorrespondenceMessages(accommodationId);
    return data;
  }
);

export const postCorrespondenceMessage = createAsyncThunk(
  "accommodation/postCorrespondenceMessage",
  async (data: CorrespondenceRequestPayload) => {
    return await CorrespondenceService.postCorrespondenceMessage(data);
  }
);

export const getEmployeeInfo = createAsyncThunk("accommodation/getEmployeeInfo", async (alias: string) => {
  const { data } = await PeopleApiService.getEmployeeInfo(alias);
  return data;
});

export interface CorrespondenceState {
  correspondenceMessages: CorrespondenceResponsePayload[];
  correspondencePostStatus: ApiCallStatus;
  correspondenceMessagesStatus: ApiCallStatus;
  correspondenceEmployeeInfo: any;
}

const initialState: CorrespondenceState = {
    correspondenceMessages: [],
    correspondencePostStatus: ApiCallStatus.Idle,
    correspondenceMessagesStatus: ApiCallStatus.Idle,
    correspondenceEmployeeInfo: {
      fullName: "",
      alias: "",
      managerFullName: "",
      managerAlias: "",
      jobTitle: ""
    }
};

// Correspondence Slice
const { reducer, actions } = createSlice({
  name: "correspondenceSlice",
  initialState,
  reducers: {
    setCorrespondencePostStatus: (state, { payload }) => {
      state.correspondencePostStatus = payload;
    },
    resetState: () => {
        return initialState;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCorrespondenceMessages.pending, (state) => {
      state.correspondenceMessagesStatus = ApiCallStatus.Pending;
    });

    builder.addCase(getCorrespondenceMessages.fulfilled, (state, { payload }) => {
      state.correspondenceMessagesStatus = ApiCallStatus.Success;
      state.correspondenceMessages = sortBasedOnStrategy(SortStrategy.DATE, [...payload], "createdOnDateTime", "ascending");
    });

    builder.addCase(getCorrespondenceMessages.rejected, (state, { payload }) => {
      state.correspondenceMessagesStatus = ApiCallStatus.Error;
    });
    builder.addCase(postCorrespondenceMessage.pending, (state) => {
      state.correspondencePostStatus = ApiCallStatus.Pending;
    });
    builder.addCase(postCorrespondenceMessage.fulfilled, (state) => {
      state.correspondencePostStatus = ApiCallStatus.Success;
    });
    builder.addCase(postCorrespondenceMessage.rejected, (state) => {
      state.correspondencePostStatus = ApiCallStatus.Error;
    });
    builder.addCase(getEmployeeInfo.fulfilled, (state, { payload }) => {
      state.correspondenceEmployeeInfo.alias = payload.basicInfo?.login;
      state.correspondenceEmployeeInfo.jobTitle = payload.basicInfo?.businessTitle;
      state.correspondenceEmployeeInfo.managerAlias = payload.basicInfo?.managerLogin;
      state.correspondenceEmployeeInfo.managerFullName = payload.basicInfo?.managerName;
      state.correspondenceEmployeeInfo.fullName = `${payload.basicInfo?.firstName} ${payload.basicInfo?.lastName}`;
    });
  }
});

export const { setCorrespondencePostStatus, resetState } = actions;

export default reducer;
