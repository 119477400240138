import Column from "@amzn/meridian/column";
import SearchField from "@amzn/meridian/search-field";
import Table, {
  TableCell,
  TableRow,
  TableActionBar,
} from "@amzn/meridian/table";
import { TableSortDirection } from "@amzn/meridian/table/table";
import Text from "@amzn/meridian/text";
import UncontrolledExpandableRow from "src/components/shared/uncontrolledExpandableRow/uncontrolledExpandableRow";
import React, { useCallback, useEffect, useState } from "react";
import {
  getJob,
  setJmrFilter,
  setJmrResults,
  setIsTldAlertModalOpen,
  TableDataRow,
  JmrJobTypes,
} from "src/store/newJmrPageSlice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { filterSortAndPaginateTableData } from "src/utils/tableUtils";
import plusCircleTokens from "@amzn/meridian-tokens/base/icon/plus-circle";
import checkCircleTokens from "@amzn/meridian-tokens/base/icon/check-circle";
import Icon from "@amzn/meridian/icon";
import Button from "@amzn/meridian/button";
import TaskRestrictionsPopover from "../taskRestrictionsPopover/taskRestrictionsPopover";
import { SortStrategy } from "src/utils/sortStrategies";
import { removeSpecialCharactersFromString } from "src/utils/helpers";

function resultsTable() {

  const {
    jmrFilter,
    jmrResults,
    tldAlertModal,
  } = useAppSelector((state) => state.newJmrPage);

  const dispatch = useAppDispatch();
  const [sortColumn, setSortColumn] = useState("rank");
  const [sortDirection, setSortDirection] = useState<TableSortDirection>("ascending");
  const [sortedTableData, setSortedTableData] = useState(jmrResults);

  const onSort = useCallback(({ sortColumn, sortDirection } : {
    sortColumn: string,
    sortDirection: TableSortDirection,
  }) => {
    setSortDirection(sortDirection);
    setSortColumn(sortColumn);
  }, []);

  useEffect(() => {
    // filter, sorting, and pagination
    const filteredData = jmrResults.filter(
      (row) => row.jobType === jmrFilter.jobType
    );
    const [paginatedData, totalPages] = filterSortAndPaginateTableData(
      filteredData,
      jmrFilter.searchField,
      sortColumn,
      sortDirection,
      jmrFilter.currentPage,
      ["jobId", "taskRestrictions"],
      jmrFilter.perPage,
      sortColumn === "rank" ? SortStrategy.NUMBER : SortStrategy.DEFAULT
    );
    setSortedTableData(paginatedData as TableDataRow[]);
    dispatch(setJmrFilter({ ...jmrFilter, totalPages }));
  }, [
    sortColumn,
    sortDirection,
    jmrResults,
    jmrFilter.searchField,
    jmrFilter.currentPage,
    jmrFilter.jobType,
    jmrFilter.perPage,
  ]);

  useEffect(() => {
    // go back to first page when filter/sort
    if (jmrFilter.currentPage !== 1) {
      dispatch(setJmrFilter({ ...jmrFilter, currentPage: 1 }));
    }
  }, [jmrFilter.searchField, sortColumn, sortDirection, jmrFilter.perPage]);

  // select a job to show in placement selection
  const checkJob = (jobId: number, id: string) => {
    dispatch(getJob(jobId));
    dispatch(
      setJmrResults(
        jmrResults.map(r => (
          r.id === id 
            ? { ...r, isChecked: !r.isChecked } 
            : r
          )
        )
      )
    );
  };

  // create data cy based on job name
  const createDataCyForJob = (jobName: string) => {
    return `new-jmr__results__job-${removeSpecialCharactersFromString(
      jobName
    )}`;
  };

  return (
    <Table
      headerRows={1}
      rowComponents={[UncontrolledExpandableRow, TableRow]}
      onSort={onSort}
      sortColumn={sortColumn}
      sortDirection={sortDirection}
      data-cy="new-jmr__results__table"
    >
      <TableActionBar widths={[300, "fill", 120]}>
        <SearchField
          aria-label="Label"
          type="text"
          size="medium"
          placeholder="Search"
          value={jmrFilter.searchField}
          onChange={(searchField) => {
            dispatch(setJmrFilter({ ...jmrFilter, searchField }));
          }}
          onSubmit={() => {}}
        />
      </TableActionBar>
      <TableRow>
        <TableCell sortColumn="rank">Rank</TableCell>
        <TableCell sortColumn="name">Job Name</TableCell>
        <TableCell>Job Type</TableCell>
        <TableCell>Task Restrictions</TableCell>
        <TableCell sortColumn="shiftCode">Shifts</TableCell>
        <TableCell sortColumn="jobCapacity">Job Capacity (hrs)</TableCell>
        <TableCell></TableCell>
      </TableRow>
      {sortedTableData.map((row, index) => (
        <TableRow
          key={index}
          backgroundColor={
            row.isChecked || row.disable ? "secondary" : "primary"
          }
        >
          <TableCell data-cy={`${createDataCyForJob(row.name)}-rank`}>
            {row.rank}
          </TableCell>
          <TableCell>
            <Column spacing={"200"}>
              <Text>{row.name}</Text>
            </Column>
          </TableCell>
          <TableCell>{row.jobType}</TableCell>
          <TableCell>
            {!row.matchedTasks && <Text color="error">-</Text>}
            {row.matchedTasks?.length === 1 && (
              <Column spacing={"200"}>
                <Text>{row.matchedTasks[0].taskName}</Text>
                <Text type="b200" color="secondary">
                  Requirement Hours: {row.matchedTasks[0].originalValue}
                </Text>
                <Text type="b200" color="secondary">
                  Restricted hours: {row.matchedTasks[0].restrictedValue}
                </Text>
              </Column>
            )}
            {row.matchedTasks?.length > 1 && (
              <TaskRestrictionsPopover taskRestrictions={row.matchedTasks} />
            )}
          </TableCell>
          <TableCell>
            {row.shiftCode ? (
              <Text>{row.shiftCode}</Text>
            ) : (
              <Text color="error">-</Text>
            )}
          </TableCell>
          <TableCell>
            {row.jobCapacity ? (
              <Text>{row.jobCapacity} hrs</Text>
            ) : (
              <Text color="error">-</Text>
            )}
          </TableCell>
          <TableCell>
            <Button
              data-cy={`${createDataCyForJob(row.name)}`}
              type="link"
              className="new-jmr__check-job"
              onClick={() => {
                if (
                  jmrFilter.jobType === JmrJobTypes.TLD &&
                  !tldAlertModal.hasBeenOpened
                ) {
                  dispatch(
                    setIsTldAlertModalOpen({
                    open: true,
                    resultId: row.id,
                    jobId: row.jobId,
                  })
                )
                } else {
                  checkJob(row.jobId, row.id)
                }
              }}
            >
              <Icon tokens={(
                row.isChecked 
                  ? checkCircleTokens 
                  : plusCircleTokens
                )}
              />
            </Button>
          </TableCell>
        </TableRow>
      ))}
    </Table>
  );
}

export default resultsTable;
