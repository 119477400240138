import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";
import userSlice from "src/store/userSlice";
import sitesSlice from "src/store/siteSlice";
import defaultSettingsPageSlice from "src/store/defaultSettingsPageSlice";
import errorBoundarySlice from "src/store/errorBoundarySlice";
import toastsSlice from "src/store/toastsSlice";
import taskConfigPageSlice from "src/store/taskConfigPageSlice";
import siteConfigPageSlice from "src/store/siteConfigPageSlice";
import regionTasksConfigPageSlice from "src/store/regionTasksPageSlice";
import adminConfigPageSlice from "src/store/adminConfigPageSlice";
import jobConfigPageSlice from "src/store/jobConfigPageSlice";
import createNewJobSlice from "src/store/createNewJobSlice";
import updateShiftsSlice from "src/store/updateShiftsPageSlice";
import emailDistributionPageSlice from "src/store/emailDistributionPageSlice";
import updateWebHookSlice from "src/store/updateWebHookSlice";
import updateSiteCapacitySlice from "src/store/updateSiteCapacitySlice";
import placementOverviewPageSlice from "src/store/placementOverviewPageSlice";
import newJmrPageSlice from "src/store/newJmrPageSlice";
import placementDetailsPageSlice from "src/store/placementDetailsPageSlice/placementDetailsPageSlice";
import correspondenceSlice from "src/store/correspondenceSlice";

export const reducers = {
  user: userSlice,
  sites: sitesSlice,
  defaultSettingsPage: defaultSettingsPageSlice,
  errorBoundary: errorBoundarySlice,
  toasts: toastsSlice,
  taskConfigPage: taskConfigPageSlice,
  siteConfigPage: siteConfigPageSlice,
  jobConfigPage: jobConfigPageSlice,
  createNewJobPage: createNewJobSlice,
  regionTasksConfigPage: regionTasksConfigPageSlice,
  adminConfigPage: adminConfigPageSlice,
  updateShiftsPage: updateShiftsSlice,
  emailDistributionPage: emailDistributionPageSlice,
  updateWebHookSlice: updateWebHookSlice,
  updateSiteCapacityPage: updateSiteCapacitySlice,
  placementOverviewPage: placementOverviewPageSlice,
  newJmrPage: newJmrPageSlice,
  placementDetailsPage: placementDetailsPageSlice,
  correspondence: correspondenceSlice
};

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false // to allow non serializable objects to be stored in redux (eg: Map, Set, classes etc.)
  }),
});
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
