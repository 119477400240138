import { TOAST_TIMEOUT } from "src/config/Toast";
import store from "src/store";
import { createToast } from "src/store/toastsSlice";
import apiClient from "src/utils/apiClient";

export interface CorrespondenceRequestPayload {
  accommodationId: number;
  correspondence: string;
}

export interface CorrespondenceResponsePayload {
  createdByName: string;
  createdByAlias: string;
  createdOnDateTime: string;
  content: string;
  fromDecisionHub: boolean;
}

class CorrespondenceService {
  async getCorrespondenceMessages(accommodationId: number) {
    return await apiClient.get(`/accommodation/${accommodationId}/correspondence`);
  }

  async postCorrespondenceMessage(data: CorrespondenceRequestPayload) {
    const res = await apiClient.post("/accommodation/correspondence", data);
    if (res) {
      store.dispatch(
        createToast({
          type: "success",
          message: "Posted correspondence message successfully",
          timeout: TOAST_TIMEOUT
        })
      );
    }
    return res;
  }
}

export default new CorrespondenceService();
