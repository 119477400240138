export const SUBMIT_FEATURE_REQUEST_AND_BUG_URL =
  "https://river.amazon.com/?org=GMH&buildingId=BLR22&q0=10d16e8f-dba1-48a0-82ca-2e31c1a8ef59"
export const BADGE_PHOTO_THUMBNAIL_URL =
  "https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=";
export const RTW_WIKI_URL =
  "https://w.amazon.com/bin/view/HealthSolutions/ReturnToWork/";
export const WEBHOOKS_LINK =
  "https://w.amazon.com/bin/view/HealthSolutions/ReturnToWork/Webhooks";
export const RTW_FAQ_LINK =
  "https://w.amazon.com/bin/view/HealthSolutions/ReturnToWork/FAQs/";
export const RTW_TRAINING_LINK =
  "https://w.amazon.com/bin/view/HealthSolutions/ReturnToWork/Training/";
export const PHONE_TOOL_URL = "https://phonetool.amazon.com/users/";
