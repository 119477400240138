import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SitesService from "src/services/sites/sitesService";
import SitesCoLocationService from "src/services/sites/sitesCoLocationService";
import { Site, SitePayload } from "src/services/sites/types";
import { Region } from "./taskConfigPageSlice";
import { DEFAULT_TABLE_PAGE_SIZE } from "src/constants/Pagination";

export interface Country {
  id: number;
  name: string;
  createdAt: string;
}

export interface Org {
  id: number;
  name: string;
}

export interface SubOrg {
  id: number;
  name: string;
}

export interface BuildingType {
  createdAt: string;
  id: number;
  label: string;
}

export interface TableDataRow {
  id: number;
  site: string;
  country: string;
  region: string;
  org: string;
  subOrg: string;
  buildingType: string;
  isActive: boolean;
  description: string;
  headcount: number;
  actAvailable: boolean;
  tldHeadcount: number;
  siteCoLocations: string;
  participatingInDecisionHubPilot: boolean;
}

export interface SiteModalFieldLength {
  maxSiteCharLength: number;
  maxDescriptionCharLength: number;
}
const siteModalFieldLengthValues: SiteModalFieldLength = {
  maxSiteCharLength: 255,
  maxDescriptionCharLength: 500,
};

export type SiteConfigPageState = {
  isLoading: boolean;
  tableData: TableDataRow[];
  currentPage: number;
  totalPages: number;
  perPage: number;
  searchField: string;
  isCreateModalOpen: boolean;
  sites: Site[];
  countries: Country[];
  regions: Region[];
  orgs: Org[];
  subOrgs: SubOrg[];
  buildingTypes: BuildingType[];
  selectedSite?: TableDataRow;
  siteModalFieldLengths: SiteModalFieldLength;
  siteCoLocationMap: Map<number, Set<string>>;
};

const initialState: SiteConfigPageState = {
  isLoading: true,
  tableData: [],
  currentPage: 1,
  totalPages: 0,
  perPage: DEFAULT_TABLE_PAGE_SIZE,
  searchField: "",
  isCreateModalOpen: false,
  sites: [],
  countries: [],
  regions: [],
  orgs: [],
  subOrgs: [],
  buildingTypes: [],
  siteModalFieldLengths: siteModalFieldLengthValues,
  siteCoLocationMap: new Map<number, Set<string>>()
};

/**
 * convert a table row to a site object
 */
export const tableRowToSite = (
  tableRow: TableDataRow,
  countries: Country[],
  orgs: Org[],
  subOrgs: SubOrg[],
  buildingTypes: BuildingType[],
  regions: Region[]
): SitePayload => {
  const site: SitePayload = {
    site: tableRow.site,
    countryId: countries.find(
      (country: { name: string }) => country?.name === tableRow.country
    )?.id,
    organizationId: orgs.find(
      (org: { name: string }) => org?.name === tableRow.org
    )?.id,
    subOrganizationId: subOrgs.find(
      (subOrg: { name: string }) => subOrg?.name === tableRow.subOrg
    )?.id,
    buildingTypeId: buildingTypes.find(
      (buildingType: { label: string }) =>
        buildingType?.label === tableRow.buildingType
    )?.id,
    regionId: regions.find(
      (region: { name: string }) => region?.name === tableRow.region
    )?.id,
    isActive: tableRow.isActive,
    description: tableRow.description,
    headcount: tableRow.headcount ? tableRow.headcount : 100, //Temp Value
    actAvailable: tableRow.actAvailable,
    participatingInDecisionHubPilot: tableRow.participatingInDecisionHubPilot ?? false,
  } as SitePayload;
  site.id = tableRow.id ?? site.id;
  return site;
};

/**
 * convert site object to a table row
 */
export const siteToTableRow = (
  site: any,
  countries: Country[],
  orgs: Org[],
  subOrgs: SubOrg[],
  buildingTypes: BuildingType[],
  regions: Region[],
  coLocatedSites: string
): TableDataRow => {

  const tableRow: TableDataRow = {
    site: site?.site,
    country: site?.country?.name
      ? site?.country?.name
      : countries.find(
          (country: { id: number }) => country?.id === site.countryId
        )?.name,
    org: site?.organization?.name
      ? site?.organization?.name
      : orgs.find((org: { id: number }) => org?.id === site.organizationId)
          ?.name,
    subOrg: site?.subOrganization?.name
      ? site?.subOrganization?.name
      : subOrgs.find(
          (subOrg: { id: number }) => subOrg?.id === site.subOrganizationId
        )?.name,
    buildingType: site?.buildingType?.label
      ? site?.buildingType?.label
      : buildingTypes.find(
          (buildingType: { id: number }) =>
            buildingType?.id === site.buildingTypeId
        )?.label,
    region: site?.region?.name
      ? site?.region?.name
      : regions.find((region: { id: number }) => region?.id === site.regionId)
          ?.name,
    isActive: site?.isActive,
    description: site?.description ?? "",
    headcount: site?.headcount,
    actAvailable: site?.actAvailable,
    tldHeadcount: site?.tldHeadcount,
    siteCoLocations: coLocatedSites,
    participatingInDecisionHubPilot: site?.participatingInDecisionHubPilot,
  } as TableDataRow;
  tableRow.id = site.id ?? tableRow.id;
  return tableRow;
};

/**
 * get all sites
 * @returns {Object}
 */
export const getAllSites = createAsyncThunk("sites/getSites", async () => {
  const { data } = await SitesService.getSites();
  return data;
});

/**
 * get all countries
 * @returns {Object}
 */
export const getCountries = createAsyncThunk("sites/getCountries", async () => {
  const { data } = await SitesService.getCountries();
  return data;
});

/**
 * get all regions
 * @returns {Object}
 */
export const getRegions = createAsyncThunk("sites/getRegions", async () => {
  const { data } = await SitesService.getRegions();
  return data;
});

/**
 * get all orgs
 * @returns {Object}
 */
export const getOrgs = createAsyncThunk("sites/getOrgs", async () => {
  const { data } = await SitesService.getOrgs();
  return data;
});

/**
 * get all subOrgs
 * @returns {Object}
 */
export const getSubOrgs = createAsyncThunk("sites/getSubOrgs", async () => {
  const { data } = await SitesService.getSubOrgs();
  return data;
});

/**
 * get all buildingTypes
 * @returns {Object}
 */
export const getBuildingTypes = createAsyncThunk(
  "sites/getBuildingTypes",
  async () => {
    const { data } = await SitesService.getBuildingTypes();
    return data;
  }
);

export const addOrEditSite = createAsyncThunk(
  "sites/createOrUpdateSite",
  async (site: any, { getState }) => {
    const state: any = getState();
    const payload = tableRowToSite(
      site,
      state.siteConfigPage.countries,
      state.siteConfigPage.orgs,
      state.siteConfigPage.subOrgs,
      state.siteConfigPage.buildingTypes,
      state.siteConfigPage.regions
    );
    const res = await SitesService.createOrUpdateSite(
      payload,
      state.siteConfigPage.selectedSite
    );

    if (res) {
      payload.id = payload.id ?? res.data.id;
      return {
        ...siteToTableRow(
          payload,
          state.siteConfigPage.countries,
          state.siteConfigPage.orgs,
          state.siteConfigPage.subOrgs,
          state.siteConfigPage.buildingTypes,
          state.siteConfigPage.regions,
          site.siteCoLocations,
        ),
      };
    }
  }
);

/** Site Config Page Slice */
const { reducer, actions } = createSlice({
  name: "siteConfigPageSlice",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSearchField: (state, action) => {
      state.searchField = action.payload;
    },
    setIsCreateModalOpen: (state, action) => {
      state.isCreateModalOpen = action.payload;
    },
    setSelectedSite: (state, action) => {
      state.selectedSite = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllSites.fulfilled, (state, { payload }) => {
      state.siteCoLocationMap = SitesCoLocationService.generateSiteCoLocationMap(payload);
      state.tableData = payload.map((site: Site) => {
        let coLocatedSites = SitesCoLocationService.getCoLocatedSites(site, state.siteCoLocationMap);
        return siteToTableRow(
          site,
          state.countries,
          state.orgs,
          state.subOrgs,
          state.buildingTypes,
          state.regions,
          coLocatedSites,
        )}
      );
      state.isLoading = false;
    });
    builder.addCase(getCountries.fulfilled, (state, { payload }) => {
      state.countries = payload;
    });
    builder.addCase(getRegions.fulfilled, (state, { payload }) => {
      state.regions = payload;
    });
    builder.addCase(getOrgs.fulfilled, (state, { payload }) => {
      state.orgs = payload;
    });
    builder.addCase(getSubOrgs.fulfilled, (state, { payload }) => {
      state.subOrgs = payload;
    });
    builder.addCase(getBuildingTypes.fulfilled, (state, { payload }) => {
      state.buildingTypes = payload.sort((a: BuildingType, b: BuildingType) => {
        return a.label.localeCompare(b.label);
      });
    });
    builder.addCase(addOrEditSite.fulfilled, (state, { payload }) => {
      if (payload) {
        state.searchField = payload.site;

        const isAlreadyInTable = state.tableData.some((row) => {
          return row.id === payload.id;
        });

        if (isAlreadyInTable) {
          // this will be the case when editing an existing site
          state.tableData = state.tableData.map((row) =>
            row.id === payload.id ? payload : row
          );
        } else {
          // this will be the case when adding a new site
          state.tableData = [...state.tableData, payload];
        }
      }
      state.isLoading = false;
    });
  },
});

export const {
  setIsLoading,
  setSearchField,
  setIsCreateModalOpen,
  setSelectedSite,
  setCurrentPage,
  setTotalPages,
  setPerPage,
} = actions;

export default reducer;
