import React from "react";
import { Navigate, useParams } from "react-router-dom";
import { PLACEMENT_DETAILS_BASEURL } from "src/constants/Routes";

function PlacementDetailsRoute() {
  let params = useParams();
  return (
    <Navigate
      to={`${PLACEMENT_DETAILS_BASEURL}/${params.placementId}`}
      replace={true}
    />
  );
}

export default PlacementDetailsRoute;
