import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ErrorRecord } from "src/hooks/useForm";
import SitesService from "src/services/sites/sitesService";
import TasksService from "src/services/tasksService";
import RegionTasksService from "src/services/regionTasksService";
import { TaskPayload } from "src/services/tasksService";
import { TableDataRow } from "./jobConfigPageSlice";
import { Site } from "./siteSlice";
import { Region } from "./taskConfigPageSlice";
import { Task } from "./regionTasksPageSlice";
import JobsService, { JobPayload } from "src/services/jobsService";

export const initialFormData = {
  id: undefined,
  name: "",
  region: "",
  jobType: "Process Path",
  siteType: "",
  active: false,
  description: "",
  jobApplicability: "siteType",
  pushToAllSites: false,
  applicableSites: [],
  tasks: [],
};

export interface JobRegionTask extends TaskPayload {
  rank: number;
}

export interface JobTask extends Task {
  bodyLocationType: string;
  requirementName: string;
}

export interface Type {
  name: string;
  id: number;
}

export interface SiteType {
  label: string;
  id: number;
}

export interface JobFieldLength {
  maxJobNameCharLength: number;
  maxDescriptionCharLength: number;
}

const jobFieldLengthValues: JobFieldLength = {
  maxJobNameCharLength: 255,
  maxDescriptionCharLength: 200,
}

export type CreateNewJobPageState = {
  regions: Region[];
  types: Type[];
  tasks: JobRegionTask[];
  siteTypes: SiteType[];
  specificSites: Site[];
  taskNames: string[];
  selectedTaskName: string;
  jobFormData?: TableDataRow;
  jobFormErrors?: ErrorRecord<TableDataRow>;
  requirementFormErrors?: any;
  restrictionFormName: string;
  selectedJobId?: number;
  isDisabled: boolean;
  jobFieldLengths: JobFieldLength;
};

const initialState: CreateNewJobPageState = {
  regions: [],
  tasks: [],
  types: [],
  siteTypes: [],
  specificSites: [],
  taskNames: [],
  selectedTaskName: "",
  jobFormData: initialFormData,
  requirementFormErrors: {},
  restrictionFormName: "",
  isDisabled: false,
  jobFieldLengths: jobFieldLengthValues,
};

export const jobPayloadToTableRow = (job: JobPayload) => {
  const row = {
    id: job?.id,
    name: job?.name,
    description: job?.description ?? "",
    jobType: job?.jobType?.name,
    siteType: job?.siteType?.label,
    region: job?.region?.name,
    applicableSites: job?.specificSites?.map((s) => s.site),
    active: job?.active,
    jobApplicability: job?.siteType ? "siteType" : "siteSpecific",
    tasks: job?.jobRequirements.map((r) => ({
      taskId: r?.task.id,
      label: r?.task.label,
      bodyLocationType: r?.bodyLocation,
      identifyBodyLocation: r?.bodyLocation ? true : false,
      requirementName:
        r?.task.measureUnit?.name === "YES/NO"
          ? r?.value === "0"
            ? "No"
            : "Yes"
          : r?.value,
      measureUnit: r?.task?.measureUnit?.name,
    })),
  } as TableDataRow;
  return row;
};

/**
 * get regions
 * @returns {Object}
 */
export const getRegions = createAsyncThunk("jobs/getRegions", async () => {
  const { data } = await TasksService.getRegions();
  return data;
});

/**
 * get specific sites
 * @returns {Object}
 */
export const getSpecificSites = createAsyncThunk(
  "jobs/getSpecificSites",
  async (regionId: number) => {
    const { data } = await SitesService.getRegionSites(regionId);
    return data;
  }
);

/**
 * get job types
 * @returns {Object}
 */
export const getJobTypes = createAsyncThunk("jobs/getJobTypes", async () => {
  const { data } = await JobsService.getJobTypes();
  return data;
});

/**
 * get all tasks
 * @returns {Object}
 */
export const getRegionTasks = createAsyncThunk(
  "jobs/getRegionTasks",
  async (regionId: number) => {
    const { data } = await RegionTasksService.getTasksByRegionId(regionId);
    return data;
  }
);

/**
 * get site types
 * @returns {Object}
 */
export const getBuildingTypes = createAsyncThunk(
  "jobs/getBuildingTypes",
  async () => {
    const { data } = await SitesService.getBuildingTypes();
    return data;
  }
);

/**
 * Get job by id
 */
export const getJobById = createAsyncThunk(
  "jobs/getJobById",
  async (jobId: number) => {
    const { data } = await JobsService.getJobById(jobId);
    return data;
  }
);

/** Job Config Page Slice */
const { reducer, actions } = createSlice({
  name: "jobConfigPageSlice",
  initialState,
  reducers: {
    setIsDisabled: (state, action) => {
      state.isDisabled = action.payload;
    },
    setSelectedTaskName: (state, action) => {
      state.selectedTaskName = action.payload;
    },
    setTaskNames: (state, action) => {
      state.taskNames = action.payload;
    },
    setJobFormData: (state, action) => {
      state.jobFormData = action.payload;
    },
    setJobFormErrors: (state, action) => {
      state.jobFormErrors = action.payload;
    },
    setRequirementFormErrors: (state, action) => {
      state.requirementFormErrors = action.payload;
    },
    setRestrictionFormName: (state, action) => {
      state.restrictionFormName = action.payload;
    },
    setSelectedJobId: (state, action) => {
      state.selectedJobId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBuildingTypes.fulfilled, (state, { payload }) => {
      state.siteTypes = payload.sort((a: SiteType, b: SiteType) => {
        return a.label.localeCompare(b.label);
      });
    });
    builder.addCase(getJobTypes.fulfilled, (state, { payload }) => {
      state.types = payload;
    });
    builder.addCase(getRegions.fulfilled, (state, { payload }) => {
      state.regions = payload;
    });
    builder.addCase(getRegionTasks.fulfilled, (state, { payload }) => {
      state.tasks = payload;
    });
    builder.addCase(getSpecificSites.fulfilled, (state, { payload }) => {
      state.specificSites = payload;
    });
    builder.addCase(getJobById.fulfilled, (state, { payload }) => {
      state.jobFormData = jobPayloadToTableRow(payload);
      state.taskNames = payload.jobRequirements.map((r: any) => r.task.label);
    });
  },
});

export const {
  setIsDisabled,
  setSelectedTaskName,
  setTaskNames,
  setJobFormData,
  setJobFormErrors,
  setRequirementFormErrors,
  setRestrictionFormName,
  setSelectedJobId,
} = actions;

export default reducer;
