import React, { useEffect, useState } from "react";
import Breadcrumbs from "src/components/shared/breadcrumbs/breadcrumbs";
import ProgressTracker from "./progressTracker/progressTracker";
import "./newJMR.scss";
import Row from "@amzn/meridian/row";
import { useAppDispatch, useAppSelector } from "src/store/store";
import BackgroundPageAUSTIN from "./backgroundPage/backgroundPageAUSTIN";
import RestrictionsPage from "./restrictionsPage/restrictionsPage";
import ResultsPage from "./resultsPage/resultsPage";
import ReviewPage from "./reviewPage/reviewPage";
import SendForApprovalPage from "./sendForApprovalPage/sendForApprovalPage";
import ConfirmModal from "src/components/shared/confirmModal/confirmModal";
import {
  setIsCancelModalOpen,
  setJmrResults,
  setPageProgress,
  setSelectedTaskNames,
  setSelectedTasks,
  setCreatedAccommodationId,
  setBackgroundFormData,
  BackgroundFormData,
  setApprovalFormData,
  getPlacementDetails,
  setPlacementId,
  setJmrFlowType,
  JmrFlowTypes,
  setAccommodationSavingStatus,
  resetTldAlertModal,
} from "src/store/newJmrPageSlice";
import { useNavigate, useParams } from "react-router-dom";
import { getAllowedSites } from "src/store/siteSlice";
import { PLACEMENT_DETAILS_BASEURL } from "src/constants/Routes";
import { ApiCallStatus } from "src/store/type";
import TldAlertModal from "./tldAlertModal/tldAlertModal";

const newJMR = () => {
  const {
    backgroundFormData,
    jmrFlowType,
    pageProgress,
    isCancelModalOpen,
    createdAccommodationId,
  } = useAppSelector((state) => state.newJmrPage);
  const { placementId, updateType } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    // providerPlacedOffWork will be handle by providerPlacedOffWorkModal modal
    if (createdAccommodationId > -1 && !backgroundFormData.providerPlacedOffWork) {
        jmrFlowType !== JmrFlowTypes.CreateNewAccommodation
        ? navigate(`${PLACEMENT_DETAILS_BASEURL}/${placementId}`)
        : navigate(`${PLACEMENT_DETAILS_BASEURL}/${createdAccommodationId}`);
        dispatch(setAccommodationSavingStatus(ApiCallStatus.Idle))
    }
  }, [createdAccommodationId]);

  useEffect(() => {
    if (placementId) {
      dispatch(getAllowedSites());
      dispatch(getPlacementDetails(+placementId));
      dispatch(setPlacementId(+placementId));
      dispatch(setJmrFlowType(updateType));
    } else {
      dispatch(setJmrFlowType(JmrFlowTypes.CreateNewAccommodation));
    }
    return () => {
      dispatch(setPageProgress(0));
      dispatch(setSelectedTasks([]));
      dispatch(setSelectedTaskNames([]));
      dispatch(setJmrResults([]));
      dispatch(
        setBackgroundFormData({
          caseSource: "",
          cannotWorkMoreThan40Hrs: false,
          cannotWorkNormalShiftHrs: false,
        } as BackgroundFormData)
      );
      dispatch(setCreatedAccommodationId(-1));
      dispatch(setApprovalFormData({}));
      dispatch(setJmrFlowType(JmrFlowTypes.CreateNewAccommodation));
      dispatch(resetTldAlertModal());
    };
  }, []);

  useEffect(() => {
    if (jmrFlowType === JmrFlowTypes.AlternativePlacementRecommended) {
      dispatch(setPageProgress(2));
    }
  }, [jmrFlowType]);

  return (
    <div className="new-jmr">

      {/* Top Selector */}
      <Breadcrumbs
        breadcrumbList={[
          { label: "Placements Overview", url: "/placementsOverview" },
          {
            label: jmrFlowType === JmrFlowTypes.CreateNewAccommodation ? "New Job Match Report" : "Update JMR",
            url: "/newJobMatchReport",
          },
        ]}
      />

      {/* Main Content */}
      <Row spacingInset={"500 none"} alignmentVertical={"top"} widths={["220px", "fill"]}>
        <ProgressTracker/>
        <div className="new-jmr__content">
          {pageProgress === 0 && <BackgroundPageAUSTIN />}
          {pageProgress === 1 && <RestrictionsPage />}
          {pageProgress === 2 && <ResultsPage />}
          {pageProgress === 3 && <ReviewPage />}
          {pageProgress === 4 && <SendForApprovalPage />}
        </div>
      </Row>

      {/* Modals */}
      <ConfirmModal
        modalTitle="Leave page"
        modalDescription="Leaving this page will restart your Job Match Results process."
        open={isCancelModalOpen}
        setOpen={() => dispatch(setIsCancelModalOpen(false))}
        onConfirm={() => navigate("/placementsOverview")}
        width="419px"
      />
      <TldAlertModal/>
    </div>
  );
};

export default newJMR;
